<template>
  <div>
    <div class="mall">
      <div class="content">
        <div class="sort-box">
          <div class="left">
            <div class="item" @click="sort('releaseDateOrderBy')">
              <span>发布日期</span>
              <span class="caret-wrapper">
                <i
                  class="el-icon-caret-top"
                  :class="{ active: searchData.releaseDateOrderBy == 0 }"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="{ active: searchData.releaseDateOrderBy == 1 }"
                ></i>
              </span>
            </div>
            <div class="item" @click="sort('priceOrderBy')">
              <span>价格</span>
              <span class="caret-wrapper">
                <i
                  class="el-icon-caret-top"
                  :class="{ active: searchData.priceOrderBy == 0 }"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="{ active: searchData.priceOrderBy == 1 }"
                ></i>
              </span>
            </div>
          </div>
          <div class="right"></div>
        </div>
        <div style="min-height: 560px">
          <div class="list" v-if="tableList.list.length > 0">
            <div
              class="items"
              v-for="item in tableList.list"
              :key="item.goodsId"
              @click="detail(item)"
            >
              <div class="item-title f-hide item_type">
                <!-- <div style="width:300px;text-overflow:ellipsis;white-space: nowrap;overflow: hidden;">{{ item.enterpriseName }}</div> -->
                <el-popover
                  placement="top-start"
                  title=""
                  width="200"
                  trigger="hover"
                  :content="item.enterpriseName"
                >
                  <div
                    slot="reference"
                    style="
                      width: 220px;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    "
                  >
                    {{ item.enterpriseName }}
                  </div>
                </el-popover>
                <div
                  style="font-size: 14px; color: #999; font-weight: normal"
                  v-if="$route.query.select==2||$route.query.select==3"
                >
                  {{ item.goodsStatus | goodsStatus }}
                </div>
              </div>
              <div class="info">
                <div class="info-left">
                  <p class="f-hide">{{ item.goodsName }}</p>
                  <p class="f-hide">{{ item.stockCount }}吨</p>
                </div>
                <div
                  class="price"
                  :class="[+item.price == 0 ? 'no-price' : '']"
                >
                  {{ item.price | priceFilter }}
                </div>
              </div>
              <div class="indicator f-hide2" v-if="item.title">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.title"
                  placement="top-start"
                >
                  <span
                    >商品指标：
                    <span class="indicator_span">{{ item.title }}</span></span
                  >
                </el-tooltip>
              </div>
              <div class="origin f-hide2">
                <i class="hy-icon-shouhuodizhi"></i>
                {{ item.originPlace }}{{ item.originPlaceDetail }}
              </div>
            </div>
          </div>

          <div v-show="tableList.total == 0" class="not_have">
            <div>
              <div class="image"></div>
              <div class="font">找不到对应信息</div>
            </div>
          </div>
        </div>
        <el-pagination
          v-if="tableList.total > 0"
          @current-change="handleCurrentChange"
          :currentPage="searchData.page"
          :page-size="searchData.size"
          layout="total, prev, pager, next, jumper"
          :total="tableList.total"
          background
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { reqGoodsList } from "@/api/hy";
import { mapState, mapMutations, mapActions } from "vuex";
const typeArr = [
  {
    selectTradeType: 1,
    selectOrderType: 1,
  },
  {
    selectTradeType: 1,
    selectOrderType: 2,
  },
  {
    selectTradeType: 2,
    selectOrderType: 1,
  },
  {
    selectTradeType: 2,
    selectOrderType: 2,
  },
];
export default {
  data() {
    return {
      searchData: {
        page: 1,
        size: 9,
        searchName: "",
        goodsType: "",
        originPlace: "",
        releaseDateOrderBy: null,
        priceOrderBy: null,
        selectTradeType: "", //1:挂牌,2:竞价
        selectOrderType: "", //1:销售,2:采购
        endFlag: false, //列表是否需要已结束的订单
      },
      searchDataOriginPlace: "",
    };
  },
  created() {},
  computed: {
    ...mapState("search", ["tableList"]),
  },
  filters: {
    priceFilter(val) {
      if (+val == 0) return "面议";
      return `${val}元/吨`;
    },
    goodsStatus(val) {
      switch (val) {
        case 0:
          return "未开始";
          break;
        case 1:
          return "进行中";
          break;
        case 2:
          return "已流拍";
          break;
        case 3:
          return "有中标";
          break;
        case 4:
          return "已取消";
          break;
      }
    },
  },
  mounted() {
    this.searchData.searchName = this.$route.query.search;
    this.getList();
  },
 
  methods: {
    ...mapActions({
      searchList: "search/searchList",
    }),
    ...mapMutations({
      pagechange: "search/pagechange",
    }),
    add() {
      this.$router.push({
        path:
          this.$route.query.selectTradeType == 1
            ? "/addGoods"
            : "/addBiddingGoods",
        query: {
          selectOrderType: this.$route.query.selectOrderType,
          type: this.$route.query.title,
          title: "发布" + this.$route.query.title + "信息",
        },
      });
    },
    detail(item) {
      this.$router.push({
        path: "/goodsDetail",
        query: {
          goodsId: item.goodsId,
          title: this.$route.query.title,
          ...typeArr[this.$route.query.select],
        },
      });
    },
    getList() {
      // const obj = {
      //   ...this.searchData,
      //   ...typeArr[this.$route.query.select],
      // };
      // reqGoodsList(obj)
      //   .then((ret) => {
      //     console.log(ret);
      //     if (ret.code == 0) {
      //       this.tableList.list = ret.data.records;
      //       this.tableList.total = ret.data.total;
      //     } else {
      //       this.tableList.list = [];
      //       this.tableList.total = 0;
      //     }
      //   })
      //   // eslint-disable-next-line no-unused-vars
      //   .catch((err) => {
      //     this.tableList.list = [];
      //     this.tableList.total = 0;
      //   });
    },
    handleCurrentChange(val) {
      this.pagechange(val);
      this.searchData.page = val;
      this.searchList();
    },
    typeSearch(val) {
      this.searchData.goodsType = val;
      this.getList();
    },
    searchFn() {
      this.searchData.originPlace = this.searchData.originPlace.toString();
      this.getList();
    },
    resetFn() {
      this.searchData.searchName = "";
      this.searchData.originPlace = "";
      this.getList();
    },
    sort(tag) {
      this.searchData[tag] = this.searchData[tag] == 0 ? 1 : 0;
      let obj = {
        releaseDateOrderBy: "priceOrderBy",
        priceOrderBy: "releaseDateOrderBy",
      };
      this.searchData[obj[tag]] = null;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.not_have {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .image {
    width: 105px;
    height: 97px;
    background: url("../../assets/img/not_have.png") no-repeat 100% 100%;
    background-size: 100% 100%;
  }
  .font {
    margin-top: 29px;
    font-size: 14px;
    font-weight: 400;
    color: #444444;
  }
}
.mall {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  &::before,
  &::after {
    display: table;
    content: "";
  }
}
.aside {
  // min-height: 500px;
  height: calc(100vh - 146px);
  border: 1px solid #e5e5e5;
  background: white;
  width: 186px;
  flex-shrink: 0;
  overflow: hidden;
  &::v-deep {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .t {
    line-height: 1;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #ebebeb;
    background: #f5f5f5;
    padding: 16px 0;
    overflow: hidden;
  }
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px;
  .form {
    display: flex;
    .el-cascader {
      margin-left: 10px;
      width: 350px;
    }
    .hy-btn {
      margin-left: 10px;
    }
  }
}
.sort-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ebebeb;
  height: 48px;
  margin-top: 20px;
  padding-left: 18px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  .left {
    display: flex;
    .item {
      display: flex;
      align-items: center;
      cursor: pointer;
      .caret-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-left: 5px;
        font-size: 14px;
        .active {
          color: #2484d6;
        }
      }

      & + .item {
        margin-left: 20px;
      }
    }
  }
}
.content {
  // min-height: 500px;
  flex: 1;
}
.list {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 20px;
  padding-bottom: 0;
  .items {
    border: 1px solid #ebebeb;
    min-width: 371px;
    width: 371px;
    margin: 0 20px 20px 0;
    padding: 10px;
    cursor: pointer;
    // background: linear-gradient(-45deg, #00A0FF, #0A65FF);
    box-shadow: 0px 5px 20px 0px rgba(143, 170, 249, 0.47);
    border-radius: 10px;
    &:hover {
      background: linear-gradient(-45deg, #00a0ff, #0a65ff);
      color: white;
    }
    &:nth-child(3n) {
      margin-right: 0 !important;
    }
    &:hover {
      border: 1px solid #2084d6;
    }
    .item-title {
      display: flex;
    justify-content: space-between;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 4px;
      border-bottom: 1px solid #e5e5e5;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 3px;
      p {
        width: 150px;
      }
      .price {
        flex-shrink: 0;
      }
    }
    .origin {
      margin-top: 20px;
    }
  }
}
.el-pagination {
  text-align: right;
  margin: 0 20px 20px 0;
}
.f-hide {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
.f-hide2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  font-size: 12px;
  -webkit-box-orient: vertical;
}
.info-left {
  .f-hide {
    & + .f-hide {
      margin-top: 5px;
    }
  }
}
</style>
